import React, { useState, useRef, useMemo } from 'react';

import logo from './logo.svg';
import './App.css';

// BACKEND_URL = '';

function Title() {
  return (
    <div style={{
      textAlign: 'center',
      fontSize: '20px',
    }}>
      <div style={{
        alignSelf: 'center',
        fontSize: '30px',
        fontWeight: '700',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px'
      }}>

        <div style={{
          // backgroundColor: 'red',
          width: '30px',
          height: '30px',

          // overflow: 'hidden',
          position: 'relative',
        }}>
          <img src="/calorie-monster.png" style={{
            height: '60px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
          }} />
        </div>
        CalorieMonster
      </div>
      <div style={{
        fontSize: '16px',
      }}>
        An educated caloric guess is better than a binge eating mess!
      </div>
    </div>
  )
}


function InputField(props) {
  const { value, setValue } = props;
  const [isActive, setIsActive] = useState(false);
  // const [renderRequired]

  return (
    <div style={{
      flex: '1 0 0%',
      alignSelf: 'stretch',
    }}>

      {
        props.showReq && (
          <div style={{
            color: 'red',
            fontSize: '15px',
          }}>* Required field</div>
        )
      }

      <div style={{
        // backgroundColor: 'rgba(0,0,0,0.5)',

        // width: '500px',
        // maxWidth: '90vw',
        height: isActive ? '130px' : '50px',

        padding: '2px',
        paddingLeft: '5px',
        boxSizing: 'border-box',

        // margin: '10px',

        border: `3px solid ${isActive || value.length ? "rgb(80, 80, 80)" : "rgb(240, 240, 240)"}`,
        borderRadius: '10px',

        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center',

        position: 'relative',

        transition: '0.3s all'
      }}>

        <textarea style={{
          backgroundColor: 'rgb(0,0,0,0)',
          resize: 'none',
          border: 'none',
          width: '100%',
          height: '80%',
          // height: '100%',

          color: 'black',
          fontFamily: 'Gabarito',
          fontSize: '14px',

          outline: 'none',

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        {
          !value.length && (
            <div style={{
              // width: '100%',
              height: '80%',
              color: 'gray',
              fontFamily: 'Gabarito',
              fontSize: '14px',

              position: 'absolute',
              left: '5px',
              top: '50%',
              transform: 'translateY(-50%)',

              pointerEvents: 'none'

            }}>
              {props.defaultValue}
            </div>
          )
        }

      </div>
    </div>
  )
}

const SubmitPictureSection = ({ preview, setPreview, base64Image, setBase64Image, setLoading, setFoodData, val }) => {


  const resizeImage = (image) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        let { width, height } = img;
        const maxDimension = 1024;

        if (Math.max(width, height) > maxDimension) {
          const scaleFactor = maxDimension / Math.max(width, height);
          width = width * scaleFactor;
          height = height * scaleFactor;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/png'));
      };

      img.src = base64Image;
    });
  };

  const handleClick = async () => {
    try {
      setLoading(true);

      const resizedImage = await resizeImage(base64Image);

      const response = await 
      // fetch('http://localhost:5000/upload', {
        fetch('https://1sdoddvio6.execute-api.us-west-1.amazonaws.com/dev/upload',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ image: resizedImage, description: val }),
      });

      const data = await response.json();
      console.log('Success:', data);
      setFoodData(data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    }}>
      <PictureSection setPreview={setPreview} setBase64Image={setBase64Image} />
      <SubmitButton onClick={handleClick} />

    </div>
  )
}

const DescriptionSection = (props) => {
  const [preview, setPreview] = useState(null);
  const [base64Image, setBase64Image] = useState('');

  const [val, setVal] = useState("");

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '10px',

    }}>
      <div style={{
        // backgroundColor:'red',
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'stretch',
        gap: '10px',

        overflow: 'hidden',
      }}>
        <div style={{
          backgroundColor: 'rgb(220,220,220)',
          // height:'100px',
          maxHeight: '100%',

          flex: `${preview ? 0.5 : 0} 0 0%`,
          transition: 'flex 0.5s ease-in-out',
          position: 'relative',
          // overflow: 'hidden',

          // textAlign:'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',

          overflow: 'hidden',
        }}>

          {
            preview &&
            <img
              src={preview}
              alt="Image Preview"
              style={{
                position: 'absolute',
                // width: '100%',
                height: '100%',
                objectFit: 'cover'
              }} />
          }

        </div>
        <InputField value={val} setValue={setVal} defaultValue="Describe your meal" />
      </div>
      <SubmitPictureSection
        preview={preview}
        setPreview={setPreview}
        base64Image={base64Image}
        setBase64Image={setBase64Image}
        setLoading={props.setLoading}
        setFoodData={props.setFoodData}
        val={val}
      />
    </div>
  )
}

const SubmitButton = ({ onClick }) => {
  return (
    <div style={{
      backgroundColor: 'rgb(30,30,30)',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '5px',

      fontSize: '14px',
      fontWeight: '600',
      color: 'white',
      whiteSpace: 'nowrap',

      borderRadius: '5px',
      padding: '10px 10px',
      boxSizing: 'border-box',

      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
      cursor: 'pointer',
    }}
      onClick={onClick}
    >
      Submit
    </div>
  )
}

const PictureSection = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        props.setPreview(reader.result);
        props.setBase64Image(reader.result);
        console.log('set preview')
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <div style={{
      alignSelf: 'center',
    }}>
      {/* <input type="file" accept="image/*" onChange={handleFileChange} />
      {preview && <img src={preview} alt="Image Preview" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />} */}
      {/* <button onClick={handleUpload}>Upload</button> */}

      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <div style={{
        backgroundColor: 'rgb(240,240,240)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',

        fontSize: '14px',
        fontWeight: '600',
        whiteSpace: 'nowrap',

        borderRadius: '5px',
        padding: '10px 10px',
        boxSizing: 'border-box',

        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        cursor: 'pointer',

        overflow: 'hidden',
      }}
        onClick={handleDivClick}
      >
        <img style={{ width: '60%' }} src="/icons/camera.svg" alt="" />
        Add Image
      </div>

    </div>
  );
};

function FoodItem({ label, data }) {
  const data_types = {
    carb: 'carbs.webp',
    fat: 'fats.webp',
    fiber: 'fruits.jpeg',
    protein: 'proteins.jpg'
  }


  return (
    <div style={{

      // backgroundColor: 'rgb(220,220,220)',

      padding: '10px',
      boxSizing: 'border-box',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch',

      borderRadius: '5px',
      gap: '10px',
    }}>
      <div style={{
        backgroundColor: 'gray',

        width: '60px',
        height: '60px',

        borderRadius: '100%',
        alignSelf: 'center',

        overflow: 'hidden',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
      }}>
        {
          (data.type === 'fiber' || data.type === 'carb' || data.type === 'fat' || data.type === 'protein') && (
            <img src={`/food_types/${data_types[data.type]}`} style={{
              height: '120%',

              position: 'absolute',

              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',

            }} />
          )
        }

      </div>

      <div style={{
        flex: '1 0 0%',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}>
        <div style={{
          fontWeight: '600',
          wordBreak: 'break-all',
          overflowWrap: 'break-word',
        }}>{label}</div>


        <div style={{
          // backgroundColor:'red',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
        }}>
          <div style={{
            flex: '1 0 0%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
            gap: '5px',

            overflow: 'hidden'
          }}>

            <div style={{
              color: 'rgb(150,150,150)',
              fontSize: '12px',
              fontWeight: '400',
            }}>Calories per 100g: <span style={{ color: 'rgb(80,80,80)' }}>{data.cp100g}</span></div>

            <div style={{
              color: 'rgb(150,150,150)',
              fontSize: '12px',
              fontWeight: '400',
            }}>Protein per 100g: <span style={{ color: 'rgb(80,80,80)' }}>{data.pp100g}g</span></div>

            <div style={{
              color: 'rgb(150,150,150)',
              fontSize: '12px',
              fontWeight: '400',
            }}>Estimated Amount:  <span style={{ color: 'rgb(80,80,80)' }}>{data.ae}g</span></div>
          </div>

          <div style={{

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',

            // backgroundColor:'red',
            flex: '1 0 0%',
            gap: '5px',

            fontSize: '14px',
          }}>
            <div>
              Calories:
              {
                " "
              }
              {
                (data.cp100g * data.ae / 100.0).toFixed(2)
              }
            </div>

            <div>
              Protein: {" "}
              {
                (data.pp100g * data.ae / 100.0).toFixed(2)
              }g
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

function FoodTotal({ label, amount }) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div style={{
        fontSize: '30px',
      }}>
        {amount}
      </div>

      <div>
        {label}
      </div>

    </div>
  )
}

function FoodTotals({ data }) {

  const totalCpAe = useMemo(() => {
    return Object.values(data).reduce((acc, item) => {
      return acc + (item.cp100g * item.ae / 100.0);
    }, 0);
  }, [data]);


  const totalPpAe = useMemo(() => {
    return Object.values(data).reduce((acc, item) => {
      return acc + (item.pp100g * item.ae / 100.0);
    }, 0);
  }, [data]);

  return (
    <div style={{
      // backgroundColor:'red',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',

      padding: '5px',
      boxSizing: 'border-box',

      position: 'sticky',
      bottom: 0,
    }}>
      <div style={{
        fontSize: '18px',
        fontWeight: '700',
      }}>Total Estimates</div>

      <div style={{
        // backgroundColor:'blue',
        padding: '10px',
        boxSizing: 'border-box',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}>
        <FoodTotal label="Calories" amount={totalCpAe.toFixed(2)} />
        <FoodTotal label="Protein" amount={`${totalPpAe.toFixed(2)}g`} />
        <FoodTotal label="Ratio" amount={`${((totalPpAe * 4) / totalCpAe * 100).toFixed(2)}%`} />
      </div>
    </div>
  )
}

function FoodSection({ data }) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',

      gap: '10px',
      position: 'relative'
    }}>
      {
        data &&
        <>
          {
            Object.keys(data).map((key, index) => (
              <>
                <FoodItem key={index} label={key} data={data[key]} />
                {
                  (index !== data.length - 1) &&
                  <div style={{
                    background: 'linear-gradient(to right, transparent, rgb(200,200,200) 50%, transparent)',
                    width: '100%',
                    height: '2px',
                    alignSelf: 'center',
                  }} />
                }
              </>
            ))
          }

          <FoodTotals data={data} />

        </>
      }
    </div>
  )
}

function App() {
  const [foodData, setFoodData] = useState(null);
  const [loading, setLoading] = useState(false);


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',

      overflow: 'hidden',
      padding: '20px 20px',
      boxSizing: 'border-box',

      gap: '20px',

      fontFamily: "Gabarito",
      fontOpticalSizing: 'auto',

      minHeight: 1,
      minWidth: 1,
    }}>

      <Title />
      <DescriptionSection setFoodData={setFoodData} loading={loading} setLoading={setLoading} />
      {/* <PictureSection /> */}
      <FoodSection data={foodData} loading={loading} />

      {
        loading && (
          <div style={{
            backgroundColor: 'rgba(0,0,0,0.3)',
            position: 'absolute',
            top: 0,
            left: 0,

            width: '100%',
            height: '100%',
          }}>
            <img src="/calorie-monster.png" style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              animation: 'rotation 1s infinite'
            }} />
            <style>
              {`
            @keyframes rotation {
                0% {
                    transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(0.4);
                }
                100% {
                    transform: translateX(-50%) translateY(-50%) rotate(360deg) scale(0.4);
                }
            }
            `}
            </style>

            <div style={{
              position: 'absolute',
              top: 'calc(50% + 100px)',
              left: '50%',

              fontSize: '60px',
              color: 'white',

              transform: 'translateX(-50%) translateY(-50%) scale(0.4)',
            }}>Loading...</div>
          </div>
        )
      }
    </div>
  );
}

export default App;
